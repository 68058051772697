<template>
               <VueSlickCarousel v-bind="settings" v-if="!mobileView">
                <div><router-link :to="{ name: 'Arcserve'}"><img src="assets/img/clients/arcserve.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Vmware'}"><img src="assets/img/clients/vmware.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Vicarius'}"><img src="assets/img/clients/vicarius.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Splunk'}"><img src="assets/img/clients/splunk.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/04.svg" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/14.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/05.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/06.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/07.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/08.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/09.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/10.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/11.webp" class="img-fluid" alt=""></router-link></div>
                <div><router-link :to="{ name: 'Home'}" v-scroll-to="'#contact'"><img src="assets/img/clients/12.webp" class="img-fluid" alt=""></router-link></div>
              </VueSlickCarousel>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  components: { VueSlickCarousel },
  data () {
    return {
      settings: {
        dots: true,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        touchThreshold: 5,
        autoplay: true,
        autoplaySpeed: 2000
      },
      mobileView: true
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}
</script>

<style scoped>

.img-fluid {

width: 199px !important;;
height: 100% !important;

}

</style>
